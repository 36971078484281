import { ProjectType, PaymentOptions, ProjectStatus, ProjectObjects } from '../types/project';
import moment from 'moment';
import { ParticipantType } from "src/store/types/project";

// action types
export const FETCH_PROJECT_REQUEST = 'FETCH_PROJECT_REQUEST';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAILURE = 'FETCH_PROJECT_FAILURE';

export const GET_ALL_PROJECTS_REQUEST = 'GET_ALL_PROJECTS_REQUEST';
export const GET_ALL_PROJECTS_SUCCESS = 'GET_ALL_PROJECTS_SUCCESS';
export const GET_ALL_PROJECTS_FAILURE = 'GET_ALL_PROJECTS_FAILURE';

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';

// action creators
export const fetchProjectRequest = (id: string) => ({ type: FETCH_PROJECT_REQUEST, id });
export const fetchProjectSuccess = (data) => ({ type: FETCH_PROJECT_SUCCESS, data });
export const fetchProjectFailure = error => ({ type: FETCH_PROJECT_FAILURE, error });

export const createProjectRequest = (payload) => ({ type: CREATE_PROJECT_REQUEST, payload });
export const createProjectSuccess = (data) => ({ type: CREATE_PROJECT_SUCCESS, data });
export const createProjectFailure = error => ({ type: CREATE_PROJECT_FAILURE, error });

export const updateProjectRequest = (payload) => ({ type: UPDATE_PROJECT_REQUEST, payload });
export const updateProjectSuccess = (data) => ({ type: UPDATE_PROJECT_SUCCESS, data });
export const updateProjectFailure = error => ({ type: UPDATE_PROJECT_FAILURE, error });

export const getAllProjectsRequest = () => ({ type: GET_ALL_PROJECTS_REQUEST });
export const getAllProjectsSuccess = (data) => ({ type: GET_ALL_PROJECTS_SUCCESS, data });
export const getAllProjectsFailure = error => ({ type: GET_ALL_PROJECTS_FAILURE, error });

export const voidParticipan: ParticipantType = {
    name: '',
    email: '',
    mobile: '',
}

export const EmptyPaymentOptions: PaymentOptions = {
    'PAD': false,
    'CARD': false,
    'BANK_REDIRECT': false,
}

const minDate = moment().format('YYYY-MM-DD');

export const emptyProject: ProjectType = {
    id: '',
    name: '',
    amount: 0,
    period: '',
    participants: [voidParticipan],
    participantsIDs: [''],
    goal: 0,
    directDebit: [minDate],
    currency: '',
    status: ProjectStatus.DRAFT,
    object: ProjectObjects.ROUNDAP,
    paymentOptions: EmptyPaymentOptions
}

// initial state
export const initialProjectState = {
    isLoading: false,
    data: emptyProject,
    error: null,
    saved: false
};