import { login, logout, setAuthError, register } from "../actions/user";
import { Dispatch } from "redux";
import { Credentials, Register } from "src/store/actions/user";

import { 
  signInWithEmailAndPassword, 
  signInWithPopup, 
  GoogleAuthProvider, 
  setPersistence, 
  browserSessionPersistence, 
  signOut,
  createUserWithEmailAndPassword,
  updateProfile
} from "@firebase/auth";

import { NavigateFunction } from "react-router";

import { auth } from '../../firebase';

const googleProvider = new GoogleAuthProvider();
let redirectPage = '/dashboard';

export const attemptLogin =
  (credentials: Credentials, navigate: NavigateFunction) => (dispatch: Dispatch) => {
  if (document.location.search.includes('redirect=')) {
    redirectPage = document.location.search.split("redirect=")[1];
  }
  return setPersistence(auth, browserSessionPersistence)
  .then(() => {
    signInWithEmailAndPassword(auth, credentials.email, credentials.password)
    .then(({ user }) => {
        dispatch(login(user));
        navigate(redirectPage, { replace: true });
      })
      .catch((error) => {
        dispatch(setAuthError(error));
        console.log(error);
      });
    })
    .catch((error) => {
      dispatch(setAuthError(error));
    });
  }

export const attemptRegister =
  (credentials: Register, navigate: NavigateFunction) => (dispatch: Dispatch) => {

    if (document.location.search.includes('redirect=')) {
      redirectPage = document.location.search.split("redirect=")[1];
    }

    return createUserWithEmailAndPassword(auth, credentials.email, credentials.password)
      .then(({ user }) => {
        updateProfile(user, {
          displayName: `${credentials.firstname} ${credentials.lastname}`
        }).then(() => {
          dispatch(register(user));
          navigate(redirectPage, { replace: true });
        })
      })
      .catch((error) => {
        dispatch(setAuthError(error));
        console.log(error);
      });
}

export const attemptGoogleLogin =
  (navigate: NavigateFunction) => (dispatch: Dispatch) => {
    
    if (document.location.search.includes('redirect=')) {
      redirectPage = document.location.search.split("redirect=")[1];
    }

    return signInWithPopup(auth, googleProvider)
    .then((result) => {
        const user = result.user;

        dispatch(login(user));
        navigate(redirectPage, { replace: true });
      })
      .catch((error) => {
        dispatch(setAuthError(error));
        console.log(error);
      });
  }

  export const attemptLogout = (navigate: NavigateFunction) => (dispatch: Dispatch) =>
   signOut(auth).then(() => {
    dispatch(logout());
    navigate('/', { replace: true });
  }).catch((error) => {
    // An error happened.
    console.log(error);
  });

/*export const attemptSendResetPasswordLink = (email: string, navigate: NavigateFunction) =>
  sendResetPasswordLink(email).then(() => {
    navigate("/login/forgot", { replace: true });
  });

export const attemptResetPassword = (password: string, token: string, navigate: NavigateFunction) =>
  resetPassword(password, token)
    .then(() => {
      navigate("/login", { replace: true });
    })
    .catch(() => {
      navigate(`/login/reset/${token}`, { replace: true });
    });

export const attemptRegister = (newUser: User) => () => postUser(newUser);

export const attemptGetConfirmation =
  (token: string, navigate: NavigateFunction) => (dispatch: Dispatch) =>
    getConfirmation(token).then(() => {
      navigate("/login", { replace: true });
    });

export const attemptResendConfirmation =
  (email: string, navigate: NavigateFunction) => (dispatch: Dispatch) =>
    resendConfirmation(email).catch(() => {
      navigate("/register", { replace: true });
    });

export const attemptResetRegister =
  (email: string, navigate: NavigateFunction) => (dispatch: Dispatch) =>
    resetRegister(email).catch(() => {
      navigate("/register", { replace: true });
    });
*/