import { User as UserType } from '@firebase/auth/'
export const LOGIN_USER = "LOGIN_USER";
export const REGISTER_USER = "REGISTER_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_USER = "SET_USER";
export const RESET_USER = "RESET_USER";
export const SET_AUTH_ERROR = "SET_AUTH_ERROR";

export type User = UserType;

/* export type User = {
  username: string;
  email: string;
  password: string;
};*/

export type Credentials = {
  email: string;
  password: string;
};

export type Register = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
};

export type UserAction<T> = {
  type: string;
  payload?: T;
};

export function login(credentials: User): UserAction<User> {
  return {
    type: LOGIN_USER,
    payload: credentials,
  };
}

export function register(credentials: User): UserAction<User> {
  return {
    type: REGISTER_USER,
    payload: credentials,
  };
}

export function logout(): UserAction<never> {
  return {
    type: LOGOUT_USER,
  };
}

export function setUser(user: User): UserAction<User> {
  return {
    type: SET_USER,
    payload: user,
  };
}

export function setAuthError(error: Error): UserAction<Error> {
  return {
    type: SET_AUTH_ERROR,
    payload: error,
  };
}

export function resetUser(): UserAction<never> {
  return { type: RESET_USER };
}
