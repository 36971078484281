import * as React from 'react';
import {Box, Button, Grid, List, ListItem, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { hotjar } from 'react-hotjar';

import { useTranslation, Trans } from 'react-i18next';
import {isMobile} from 'react-device-detect';
import { slide as Menu } from 'react-burger-menu';

import { useAuth } from '../../hooks/useAuth';
import HeaderUserbox from './Userbox';

import './Sidebar.css';
import { ListWrapper, MuiAvatar, MuiAvatarMobile, MenuWrapper, SubMenuWrapper } from './style';



const HeaderMenu: React.FC= () => {
  const { user } = useAuth();
  const { i18n } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  const [isOpen, setIsOpen] = React.useState(false);
  React.useEffect(()=>{
    hotjar.initialize(3402952, 6);
  },[]);
  return (
    <>
    { isMobile ? (
      <Grid container>
        <Grid item xs={2}>
          <Menu isOpen={isOpen} onStateChange={(state) => {
              setIsOpen(state.isOpen)
            }
          }>
            <MenuWrapper>
              <List component="div">
                <SubMenuWrapper>
                  <List component="div">
                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={NavLink}
                        to="/"
                        onClick={() => setIsOpen(false)}
                      >
                        <Trans i18nKey="Home"></Trans>
                      </Button>
                    </ListItem>
                  </List>
                  {user && <List component="div">
                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={NavLink}
                        to="/dashboard"
                        onClick={() => setIsOpen(false)}
                      >
                        <Trans i18nKey="Dashboard"></Trans>
                      </Button>
                    </ListItem>
                  </List>}
                  <List component="div">
                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={NavLink}
                        onClick={() => setIsOpen(false)}
                        to="/faq"
                      >
                        <Trans i18nKey="Guides"></Trans>
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>
              </List>
            </MenuWrapper>
            <List component="div">
              <SubMenuWrapper>
                <List disablePadding component={Box} display="flex">
                  {i18n.language !== 'fr' && <ListItem component="div">
                      <Button
                        disableRipple
                        onClick={() => {
                            changeLanguage("fr");  
                            setIsOpen(false);
                          } 
                        }
                      >
                        Français
                      </Button>
                    </ListItem>}
                  {i18n.language !== 'en' && <ListItem component="div">
                      <Button
                        disableRipple
                        onClick={() => {
                          changeLanguage("en");
                          setIsOpen(false);
                        }
                      }
                      >
                        English
                      </Button>
                    </ListItem>}
                </List>
              </SubMenuWrapper>
            </List>
          </Menu>
          </Grid>
          <Grid item xs={7} sx={{marginTop: 3, display: 'flex', alignItems: 'center', textAlign: 'center', marginLeft: 'auto'}}>
            <MuiAvatarMobile>
              <ListItem
                  classes={{ root: 'MuiListItem-indicators' }}
                  button
                  component={NavLink}
                  to="/"
                >
                <img
                  src="/static/images/logo/roundap-short.png"
                  alt="Material-UI"
                />
              </ListItem>
            </MuiAvatarMobile>
          </Grid>
        <Grid item xs={3} sx={{ marginTop: 1, display: 'flex'}}>
            <HeaderUserbox />
          </Grid>
        </Grid>
    )
    : 
    (<React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', mt: '20px' }}>
        <Grid container>
          <Grid item xs={2}>
            <List component={Box}>
              <ListItem
                  button
                  component={NavLink}
                  to="/"
                >
                <MuiAvatar>
                <img
                  src="/static/images/logo/roundap.png"
                  alt="Material-UI"
                />
              </MuiAvatar>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={8} sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ListWrapper>
              <List disablePadding component={Box} display="flex">
                <ListItem
                  classes={{ root: 'MuiListItem-indicators' }}
                  button
                  component={NavLink}
                  to="/"
                >
                  <Typography variant="h4" noWrap><Trans i18nKey="Home"></Trans></Typography>
                </ListItem>
                {user && <ListItem
                  classes={{ root: 'MuiListItem-indicators' }}
                  button
                  component={NavLink}
                  to="/dashboard"
                >
                  <Typography variant="h4" noWrap><Trans i18nKey="Dashboard"></Trans></Typography>
                </ListItem>}
                <ListItem
                  classes={{ root: 'MuiListItem-indicators' }}
                  button
                  component={NavLink}
                  to="/faq"
                >
                  <Typography variant="h4" noWrap><Trans i18nKey="Guides"></Trans></Typography>
                </ListItem>
              </List>
            </ListWrapper>
            <ListWrapper style={{position: 'absolute', right: '1px'}}>
              <List disablePadding component={Box} display="flex">
                {i18n.language !== 'fr' && <ListItem
                  classes={{ root: 'MuiListItem-indicators' }}
                  button
                  onClick={() => changeLanguage("fr")}
                >
                  <Typography variant="h4" noWrap>Français</Typography>
                </ListItem>}
                {i18n.language !== 'en' && <ListItem
                  classes={{ root: 'MuiListItem-indicators' }}
                  button
                  onClick={() => changeLanguage("en")}
                >
                  <Typography variant="h4" noWrap>English</Typography>
                </ListItem>}
              </List>
            </ListWrapper>
          </Grid>
          <Grid item xs={2}>
            <HeaderUserbox />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>)
    }
    </>
  );
}
export default HeaderMenu;
