import { getAuth } from '@firebase/auth'
import { initializeApp } from 'firebase/app'
import { getFirestore } from '@firebase/firestore';
import { firebaseConfig } from './firebaseConfig';
import { getAnalytics } from "firebase/analytics";

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
const analytics = getAnalytics(firebaseApp);
