import React, { useState } from 'react';
import { Form, redirect, useNavigate, useSubmit } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Divider,
  OutlinedInput,
  Grid,
  FormControl,
  InputAdornment,
  Button,
  FormHelperText,
  Card,
  CardContent,
  CardMedia
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { ArrowForwardTwoTone } from '@mui/icons-material';
import { Trans } from 'react-i18next';


import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';

import { User as UserType } from '@firebase/auth/'
// import { useAppSelector, useAppDispatch } from "src/store/hooks";
import { createProject } from "src/services/project";


const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.white[100]};
`
);

const ButtonNotify = styled(Button)(
  ({ theme }) => `
    margin-right: -${theme.spacing(1)};
`
);

interface ProjectProps {
  user: UserType
}
const Project: React.FC<ProjectProps> = ({user}) => {

  const [projectName, setProjectName] = useState('');
  const submit = useSubmit();

  const handleSubmit = (event) => {
    submit(event.currentTarget);
  }

  return (
    <>
      <Helmet>
        <title>New Project</title>
      </Helmet>
      <MainContent>
        <Container maxWidth={false} sx={{mt: 6}}>
            <Grid container>
              <Grid item sx={{ p: isMobile ? 0 : 4 }} xs={12} sm={12}>
              <Box mb={3}>
                <Container maxWidth="xs">
                  <Typography variant="h1" sx={{ mt: 4, mb: 2 }}>
                  <Trans i18nKey="project.title1"></Trans>
                  </Typography>
                  <Typography
                    variant="h3"
                    color="text.secondary"
                    fontWeight="normal"
                    sx={{ mb: 4 }}
                  >
                    <Trans i18nKey="project.subtitle1"></Trans>
                  </Typography>
                </Container>
                <Container maxWidth="sm">
                  <Form method='post'>
                    <input type="hidden" name="userId" value={user.uid} />
                    <input type="hidden" name="object" value="ROUNDAP" />

                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInputWrapper
                      type="text"
                      name="name"
                      value={projectName}
                      placeholder="Enter your project name..."
                      onChange={(event) => setProjectName(event.target.value)}
                      endAdornment={
                          <InputAdornment position="end">
                          <ButtonNotify
                            type='button'
                            variant="contained"
                            endIcon={<ArrowForwardTwoTone />}
                            onClick={handleSubmit}
                          >
                          {!isMobile && <Trans i18nKey="project.button1"></Trans>}
                          </ButtonNotify>
                          </InputAdornment>
                      }
                      startAdornment={
                          <InputAdornment position="start">
                          </InputAdornment>
                      }
                      />
                      <FormHelperText>
                      <Trans i18nKey="project.ex1"></Trans>
                      </FormHelperText>
                    </FormControl>
                  </Form>
                  <Divider sx={{ my: 4 }} />
                </Container>
              </Box>
            </Grid>
            <Grid item sx={{ textAlign: 'center', p: isMobile ? 0 : 4 }} xs={12} sm={12}>
            
            <Card>
                <CardMedia
                  sx={{ height: 640 }}
                  image="/img/pexels-oleksandr-pidvalnyi-12969361.jpg"
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                  <Trans i18nKey="cardContent.title1"></Trans>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  <Trans i18nKey="cardContent.text1"></Trans>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MainContent>
    </>
  );
}

export default Project;

export async function action({request}) {

  type ParamsType = {
    name: string,
    userId: string,
    object: string
  };

  const formData = await request.formData();
  const postData = Object.fromEntries(formData) as ParamsType;
  const result = await createProject(postData);
  if (result.id) {
    return redirect(`/project/${result.id}`)
  }
  return null;
}
