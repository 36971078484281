import Stripe from 'stripe';
import {
    initialStripeState,
    FETCH_DATA_FAILURE,
    FETCH_DATA_REQUEST,
    FETCH_DATA_SUCCESS
} from '../actions/stripe';

export type TStripeState = {
    isLoading: boolean;
    data: Stripe.Account;
    error: string | null;
};

export const stripe = (state, action) => {
    if (!state) {
        state = initialStripeState;
    }

    switch (action.type) {
        case FETCH_DATA_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data
            };
        case FETCH_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};