import { Suspense, lazy } from 'react';
import { Navigate, createBrowserRouter, useLoaderData } from 'react-router-dom';
import { RouteObject } from 'react-router';

import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

import { useAuth } from './hooks/useAuth';

import Protected from "./Protected";

import Notfound from 'src/pages/NotFound';

import { getProject } from 'src/services/project';

import { action as CreateProjectAction } from 'src/pages/project/Project';

const Loader = (Component, isProtected = false) => (props) => {
  const { pending, isSignedIn, user, auth } = useAuth();
  const loadedData = useLoaderData();
  const data = loadedData ? loadedData : null;

  props = {...props, data, user};

  return (
    <Suspense fallback={<SuspenseLoader />}>
      {pending && <div>Loading</div>}
      { !pending && isProtected === true && <Protected user={isSignedIn}><Component { ...props} /></Protected> }
      { !pending && !isProtected && <Component {...props} /> }
    </Suspense>
  );
}

// Pages
const Home = Loader(lazy(() => import('src/pages/home')));

const Faq = Loader(lazy(() => import('src/pages/faq/index')));

const Status500 = Loader(lazy(() => import('src/pages/Status/Status500'))); 

const LoginPage = Loader(
  lazy(() => import('src/pages/LoginPage'))
);

const ResetPassword = Loader(
  lazy(() => import('src/pages/ResetPassword'))
);

const SignupPage = Loader(
  lazy(() => import('src/pages/Signup'))
);

const Project = Loader(
  lazy(() => import('src/pages/project/Project')),
  true
);

const Dashboard = Loader(
  lazy(() => import('src/pages/Dashboard')),
  true
);

const ConfigProject = Loader(
  lazy(() => import('src/pages/project/ConfigProject')),
  true
);

const ValidateProject = Loader(
  lazy(() => import('src/pages/project/ValidateProject')),
  true
);

const PreviewProject = Loader(
  lazy(() => import('src/pages/project/Preview')),
  true
);

const Settings = Loader(
  lazy(() => import('src/pages/settings/index')),
  true
);

const JoinProject = Loader(
  lazy(() => import('src/pages/project/JoinProject')),
  true
);

const ProjectPay = Loader(
  lazy(() => import('src/pages/project/Pay')),
  true
);

const PaymentConfirmation = Loader(
  lazy(() => import('src/pages/project/PaymentConfirmation'))
);

const JoinProjectConfig = Loader(
  lazy(() => import('src/pages/project/JoinProjectConfig')),
  true
);

const AddLoan = Loader(
  lazy(() => import('src/pages/loan/AddLoan')),
  true
);

const ValidateLoan = Loader(
  lazy(() => import('src/pages/loan/ValidateLoan')),
  true
);

const routes = createBrowserRouter([
  {
    path: '',
    element: <BaseLayout />,
    errorElement: <Status500 />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'login',
        element: <LoginPage to="/login" replace />
      },
      {
        path: 'reset-password',
        element: <ResetPassword to="/reset-password" replace />
      },
      {
        path: 'signup',
        element: <SignupPage to="/signup" replace />
      },
      {
        path: 'project',
        element: <Project to="/project" replace />,
        action: CreateProjectAction
      },
      {
        path: 'project/:id',
        element: <ConfigProject to="/project/:id" replace />,
        loader: async ({ params }) => {
          try {
            const project = await getProject(params.id);
            return project;
          } catch (e) {
            console.log(e);
            return null;
          }
        }
      },
      {
        path: 'project/validate/:id',
        element: <ValidateProject to="/project/validate/:id" replace />,
        loader: async ({ params }) => {
          try {
            const project = await getProject(params.id);
            return project;
          } catch (e) {
            console.log(e);
            return null;
          }
        }
      },
      {
        path: 'project/preview/:id',
        element: <PreviewProject to="/project/preview/:id" replace />,
        loader: async ({ params }) => {
          try {
            const project = await getProject(params.id);
            return project;
          } catch (e) {
            console.log(e);
            return null;
          }
        },
      },
      {
        path: 'join-project/:id',
        element: <JoinProject to="/join-project/:id" replace />,
        loader: async ({ params }) => {
          try {
            const project = await getProject(params.id);
            return project;
          } catch (e) {
            console.log(e);
            return null;
          }
        },
      },
      {
        path: 'join-project/config/:id',
        element: <JoinProjectConfig replace />,
        loader: async ({ params }) => {
          try {
            const project = await getProject(params.id);
            return project;
          } catch (e) {
            console.log(e);
            return null;
          }
        },
      },
      {
        path: 'project/pay/:projectId/:beneficiaryId/:paymentIntentId',
        element: <ProjectPay replace />,
        loader: async ({ params }) => {
          try {
            const project = await getProject(params.projectId);
            return project;
          } catch (e) {
            console.log(e);
            return null;
          }
        },
      },
      {
        path: 'project/payment-confirmation',
        element: <PaymentConfirmation replace />
      },
      {
        path: 'dashboard',
        element: <Dashboard to="/dashboard" replace />
      },
      {
        path: 'settings',
        element: <Settings to="/settings" replace />
      },
      {
        path: 'faq',
        element: <Faq to="/faq" replace />
      },
      {
        path: 'loan',
        element: <AddLoan to="/loan" replace />
      },
      {
        path: 'loan/:id',
        element: <AddLoan to="/loan" replace />,
        loader: async ({ params }) => {
          try {
            const project = await getProject(params.id);
            return project;
          } catch (e) {
            console.log(e);
            return null;
          }
        }
      },
      {
        path: '/loan/validate/:id',
        element: <ValidateLoan to="/loan/validate/:id" replace />,
        loader: async ({ params }) => {
          try {
            const project = await getProject(params.id);
            return project;
          } catch (e) {
            console.log(e);
            return null;
          }
        },
      },
    ]
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      { path: '*', element: <Notfound />}
    ]
  }
]);

export default routes;
