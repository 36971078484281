export type ParticipantType = {
    name: string,
    email: string,
    mobile?: string,
    id?: string,
    action?: string
}

export interface ProjectType {
    id: string;
    name: string;
    amount: number,
    period: string,
    participants: ParticipantType[],
    participantsIDs: string[],
    goal: number,
    directDebit: string[] | null,
    currency?: string,
    status?: string,
    createdBy?: string,
    object?: string,
    paymentOptions?: PaymentOptions,
    startDate?: string,
    endDate?: string,
    creatorRole?: string,
}

export type PaymentOptions = {
    PAD: boolean;
    CARD: boolean;
    BANK_REDIRECT: boolean;
};

export const ProjectStatus = {
    DRAFT: 'DRAFT',
    PREPARING: 'PREPARING',
    ONGOING: 'ONGOING',
    DONE: 'DONE'
}

export const ProjectObjects = {
    ROUNDAP: 'ROUNDAP',
    FUNDRAISING: 'FUNDRAISING'
}