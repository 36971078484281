import { legacy_createStore as createStore, applyMiddleware, compose, Store } from "redux";

import thunk from "redux-thunk";
import buildRootReducer from "./reducers/index";
import { UserState } from "./reducers/user";
import { UserAction } from "./actions/user";
import { initialStripeState } from "./actions/stripe";
import { initialProjectState } from './actions/project';
import { TStripeState } from './reducers/stripe';
import { TProjectState } from './reducers/project'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

type AppState = { 
  user: UserState
  stripe: TStripeState,
  project: TProjectState,
};

const initialState: AppState = {
  user: {
    isAuth: false,
    user: null,
    error: null,
  },
  stripe: initialStripeState,
  project: initialProjectState,
};

export default function configureStore(
  state: AppState = initialState
): Store<AppState, UserAction<any>> {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middlewares = [thunk];

  return createStore(buildRootReducer(), state, composeEnhancers(applyMiddleware(...middlewares)));
}
