import {
    initialProjectState,
    FETCH_PROJECT_REQUEST,
    FETCH_PROJECT_SUCCESS,
    FETCH_PROJECT_FAILURE,
    CREATE_PROJECT_REQUEST,
    CREATE_PROJECT_SUCCESS,
    CREATE_PROJECT_FAILURE,
    UPDATE_PROJECT_REQUEST,
    UPDATE_PROJECT_SUCCESS,
    UPDATE_PROJECT_FAILURE,
    GET_ALL_PROJECTS_REQUEST,
    GET_ALL_PROJECTS_SUCCESS,
    GET_ALL_PROJECTS_FAILURE,
} from '../actions/project';

import { ProjectType } from '../types/project';

export type TProjectState = {
    isLoading: boolean;
    data: ProjectType;
    error: string | null;
    saved: boolean;
};

export const project = (state, action) => {
    if (!state) {
        state = initialProjectState;
    }

    switch (action.type) {
        case FETCH_PROJECT_REQUEST:
            return {
                ...state,
                isLoading: true,
                saved: false
            };
        case FETCH_PROJECT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                saved: false
            };
        case FETCH_PROJECT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                saved: false
            };
        case CREATE_PROJECT_REQUEST:
            return {
                ...state,
                isLoading: true,
                saved: false
            };
        case CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                saved: true
            };
        case CREATE_PROJECT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                saved: false
            };
        case UPDATE_PROJECT_REQUEST:
            return {
                ...state,
                isLoading: true,
                saved: false
            };
        case UPDATE_PROJECT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                saved: true
            };
        case UPDATE_PROJECT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                saved: false
            };
        case GET_ALL_PROJECTS_REQUEST:
            return {
                ...state,
                isLoading: true,
                saved: false
            };
        case GET_ALL_PROJECTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                saved: false
            };
        case GET_ALL_PROJECTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                saved: false
            };
        default:
            return state;
    }
};