import { useRef, useState } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  Link,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from "src/store/hooks";
import { attemptLogout } from "src/store/thunks/auth";
import { useAuth } from '../../../hooks/useAuth';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
    white-space: nowrap;
    margin-right: 8px;
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxTitle = styled(Typography)(
  ({ theme }) => `
        color: ${theme.colors.alpha.black[100]}
`
);

function HeaderUserbox() {
  const { t } = useTranslation();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const { pending, isSignedIn, user, auth } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(attemptLogout(navigate));
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      { !pending && !user && 
        <Box sx={{mt: 3}}>
          <Link href='/login' underline="none">
            <UserBoxTitle><Typography variant="h4">{t('login')}</Typography></UserBoxTitle>
          </Link>
        </Box>
      }
      {(!pending && user) && 
        (<UserBoxButton 
          variant="text" 
          color="secondary" 
          ref={ref} 
          onClick={handleOpen}
          endIcon={<ArrowDropDownIcon />}
        >
        { user.photoURL ? <Avatar variant="rounded" alt={user.displayName} src={user.photoURL} />
        : <UserBoxLabel variant="body1">{user.displayName}</UserBoxLabel>}
      </UserBoxButton>)
      }
      { !pending && user && <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          { user.photoURL && <Avatar variant="rounded" alt={user.displayName} src={user.photoURL} /> }
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.displayName}</UserBoxLabel>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem
            button
            to="/settings"
            component={NavLink}
            onClick={() => { setOpen(false) }}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary={t("account_settings")} />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            {t('signout')}
          </Button>
        </Box>
      </Popover>
    }
    </>
  );
}

export default HeaderUserbox;
