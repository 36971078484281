import { combineReducers } from "redux";
import {reducer as toastrReducer} from 'react-redux-toastr';

import userReducer from "./user";
import { stripe } from "./stripe";
import { project } from "./project";

const buildRootReducer = () =>
  combineReducers({
    user: userReducer,
    toastr: toastrReducer,
    stripe,
    project
  });

export default buildRootReducer;
