import { LOGIN_USER, REGISTER_USER, LOGOUT_USER, SET_USER, SET_AUTH_ERROR, RESET_USER, UserAction, User } from "../actions/user";
import { AuthError } from '@firebase/auth';

export type UserState = {
  isAuth: boolean;
  user: User | null;
  error: string | null;
};

const initialState: UserState = {
  isAuth: false,
  user: null,
  error: null
};

const formatError = (error: AuthError) => {
  switch (error.code) {
    case 'auth/user-not-found':
      return 'User Not found';
    default:
      return error.code;
  }
}

export default function user(state, action: UserAction<any>): UserState {
  if (!state) {
    state = initialState;
  }

  switch (action.type) {
    case LOGIN_USER:
      return {
        user: action.payload,
        isAuth: true,
        error: null,
      };
    case REGISTER_USER:
      return {
        user: action.payload,
        isAuth: true,
        error: null,
      };
    case LOGOUT_USER:
      return {
        isAuth: false,
        user: null,
        error: null,
      };
    case SET_USER:
      return {
        user: action.payload,
        isAuth: true,
        error: null,
      };
    case SET_AUTH_ERROR:
      return {
        error: formatError(action.payload),
        user: null,
        isAuth: null,
      };
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
}
