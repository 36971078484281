import { addDoc, collection, getDoc, getDocs, doc, updateDoc, query, where, deleteDoc  } from '@firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';
import { User } from '@firebase/auth/';
import { toastr } from 'react-redux-toastr';
import { ParticipantType } from "src/store/types/project";

import { ProjectType  } from "src/store/types/project";

const COLLECTION = 'projects';
const projectCollectionRef = collection(db, COLLECTION);
const JOIN_PROJECT_ACTION = `${process.env.REACT_APP_BACKEND_API}/joinProject`;

export const CURRENCY_SYMBOLE = {
  'CAD': 'C$',
  'USD': '$',
  'EUR': '€'
}

export const getProjects = (user: User) => {
  const q1 = query(projectCollectionRef, where("createdBy", "==", user.uid));
  const q2 = query(projectCollectionRef, where("participantsIDs", "array-contains", user.email));
  const res1 = getDocs(q1);
  const res2 = getDocs(q2);

  return Promise.all([res1, res2]);
}

export const getProject = async (id: string) => {
  const docRef = doc(db, COLLECTION, id);
  const project = await getDoc(docRef);
  return {...project.data(), id};
}

export const createProject = ({name, userId, object}) => {
  return addDoc(projectCollectionRef, {name, createdBy: userId, status: ProjectStatus.DRAFT, object })
}

export const deleteProject = (id: string) => {

  const docRef = doc(db, "projects", id);
  return deleteDoc(docRef)
}

export const updateProject = (project: ProjectType) => {
  const projectRef = doc(db, COLLECTION, project.id);
  return updateDoc(projectRef, {...project});
}

export type PaymentOptions = {
  PAD: boolean;
  CARD: boolean;
  BANK_REDIRECT: boolean;
};

export const EmptyPaymentOptions: PaymentOptions = {
  'PAD': false,
  'CARD': false,
  'BANK_REDIRECT': false,
}

export const ProjectObjects = {
  ROUNDAP: 'ROUNDAP',
  FUNDRAISING: 'FUNDRAISING'
}

export const voidParticipan: ParticipantType = {
  name: '',
  email: '',
  mobile: '',
}

const minDate = moment().format('YYYY-MM-DD');
export const ProjectStatus = {
  DRAFT: 'DRAFT',
  ONGOING: 'ONGOING',
  DONE: 'DONE'
} 

export const emptyProject: ProjectType = {
  id: '',
  name: '',
  amount: 0,
  period: '',
  participants: [voidParticipan],
  participantsIDs: [''],
  goal: 0,
  directDebit: [minDate],
  currency: '',
  status: ProjectStatus.DRAFT,
  object: ProjectObjects.ROUNDAP,
  paymentOptions: EmptyPaymentOptions
}

export const setParticipantsIDs = (participants, myEmail) => participants.map((p) => p.email).filter((e) => e != myEmail);

export const joinProject = (user, projectId) => {
  return setInviteAction(user, projectId, 'ACCEPT');
}

export const declineProject = (user, projectId) => {
  return setInviteAction(user, projectId, 'DECLINE');
}

const setInviteAction = (user, projectId, action) => {
  const raw = {projectId, user, action};

  var requestOptions = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(raw) as BodyInit,
    // mode: 'no-cors' as RequestMode,
  };

  fetch(JOIN_PROJECT_ACTION, requestOptions)
  .then(response => {
    if (response.status === 200) {
      if (action === 'ACCEPT') {
        toastr.success('Good Job', 'Thanks for joining the project');
        setTimeout(() => {
          document.location = `/project/preview/${projectId}`;
        }, 1000);
      } else {
        toastr.success('Done', 'Other users was notified about your choice.');
        document.location = '/dashboard';
      }
    } else {
      toastr.error('Error', 'An error occurred during the action');
    }
  })
  .catch(error => {
    console.log(error);
    toastr.error('Error', 'An error occurred during the action')
  });
}

export const retrievePaymentIntent = (params) => {
  const requestOptions = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(params) as BodyInit,
  };
  return fetch(`${process.env.REACT_APP_BACKEND_API}/retrievePaymentIntent`, requestOptions);
}

export const retrievePayments = (params) => {
  const requestOptions = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(params) as BodyInit,
  };
  return fetch(`${process.env.REACT_APP_BACKEND_API}/retrievePayments`, requestOptions);
}

export const setCommitment = (params) => {
  const requestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(params) as BodyInit,
  };
  return fetch(`${process.env.REACT_APP_BACKEND_API}/setCommitment`, requestOptions);
}
