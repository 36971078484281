import { Action } from 'redux';
import Stripe from 'stripe';
// action types
export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

export type StripeFetchAction<T> = {
    type: "FETCH_DATA_REQUEST";
}
export interface StripeResultAction extends Action {
    type: "FETCH_DATA_SUCCESS";
    payload: any;
}

// action creators
export const fetchDataRequest = () => ({ type: FETCH_DATA_REQUEST });
export const fetchDataSuccess = (data) => ({ type: FETCH_DATA_SUCCESS, data });
export const fetchDataFailure = error => ({ type: FETCH_DATA_FAILURE, error });

export const voidAccoutSettings: Stripe.Account = {
    id: '',
    charges_enabled: false,
    details_submitted: false,
    email: '',
    payouts_enabled: false,
    object: "account",
    type: "custom"
}
// initial state
export const initialStripeState = {
    isLoading: false,
    data: voidAccoutSettings,
    error: null
};