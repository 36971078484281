import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import { Box, Container } from '@mui/material';
import { isMobile } from 'react-device-detect';
import HeaderMenu from './header';

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <Container maxWidth={"lg"} sx={{ pr: isMobile ? 0 : 4, pl: isMobile ? 0 : 4 }}>
    <Box
      sx={{
        flex: 1,
        height: '100%'
      }}
    >
      <HeaderMenu />
      <Container maxWidth={false}>
        {children || <Outlet />}
      </Container>
    </Box>
    </Container>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
