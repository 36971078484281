import { Navigate } from "react-router-dom";

const Protected = ({ user, children }) => {
    if (!user) {
        return <Navigate to={`/login?redirect=${document.location.pathname}`} replace />;
    }

    return children;
};

export default Protected;
