import ReactDOM from "react-dom/client";
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import ReduxToastr from 'react-redux-toastr'
import { I18nextProvider } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';

import i18n from './i18n';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import { store } from "./store/store";

import { styled } from '@mui/material/styles';

const ToastrContent = styled('div')(
  () => `
    .top-center {
      width: 80%;
      left: 20%;
    }
`
);

i18n.init({debug: false});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HelmetProvider>
    <I18nextProvider i18n={i18n}>
        <SidebarProvider>
          <Provider store={store}>
              <App />
              <ToastrContent>
                <ReduxToastr
                    timeOut={4000}
                    newestOnTop={false}
                    preventDuplicates
                    position={isMobile ? "top-right" : "top-center"}
                    getState={(state) => state.toastr} // This is the default
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    closeOnToastrClick
                    />
                </ToastrContent>
          </Provider>
        </SidebarProvider>
    </I18nextProvider>
  </HelmetProvider>
);

serviceWorker.unregister();
